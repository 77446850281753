<template>
  <div class="">
    <div v-if="showError" class="w-100 text-center pt-4 px-1">
      <h2 class="mb-1">Page Not Found 🕵🏻‍♀️</h2>
      <p class="mb-2">
        Oops! 😖 The requested URL was not found on this server.
      </p>
      <b-img fluid :src="imgUrl" alt="Error page" />
    </div>

    <template v-else>
      <b-row v-if="schLoading" align-h="center" class="mb-1">
        <b-col class="text-center" lg="7" md="9">
          <b-spinner
            class="m-5"
            style="width: 4rem; height: 4rem"
            variant="primary"
            label="Spinning"
          ></b-spinner>
        </b-col>
      </b-row>

      <template v-else>
        <b-row align-h="center" class="mb-50" v-if="false">
          <b-col class="text-center" lg="7" md="9">
            <b-img
              v-if="school.logo"
              :src="school.logo"
              style="object-fit: contain"
              fluid
              height="80px"
              width="80px"
            />
            <b-img
              v-else
              src="https://cdn.cloudious.net/file-1678110140675-811622963.png"
              style="object-fit: contain"
              fluid
              height="80px"
              width="80px"
            />
            <!-- <div class="mt-1">
              <h3>{{ school.name }}</h3>
              <h5>
                <feather-icon icon="MapPinIcon" class="mr-50" />
                {{ school.address }}
              </h5>
              <h5>
                <feather-icon icon="PhoneIcon" class="mr-50" />
                {{ school.phone || school.mobile }}
              </h5>
            </div> -->
          </b-col>
        </b-row>
        <iframe
          ref="myIframe"
          class="border-0 w-100 set-aspect"
          :src="`https://p2p.mirotalk.com/join/${$route.params.id}?name=${uname}&notify=0`"
          allow="microphone; camera"
        ></iframe>
        <!-- @load="hideDiv" -->
        <!--         
         name -> direct joining
         notify -> share room card on joining 
        -->
      </template>
    </template>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BContainer,
  BMediaAside,
  BMediaBody,
  BSpinner,
  BCardTitle,
  BCardText,
  BInputGroupPrepend,
  BFormInput,
  BInputGroup,
} from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,
    BFormCheckbox,
    BFormGroup,
    BForm,
    BContainer,
    BMediaAside,
    BMediaBody,
    BSpinner,
    BCardTitle,
    BCardText,
    BInputGroupPrepend,
    BFormInput,
    BInputGroup,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      schLoading: false,
      showError: false,
      school: {
        logo: "",
        name: "",
        address: "",
        phone: "",
      },
      db: "",
      cID: 0,
      roomID: "",
      uname: "",
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkZW1vQG15c2tvb2wuYXBwIiwianRpIjoiZmRjZjdhYzQtNmY1OS00Nzg0LTkyNDItZjFjNjRhOTdjODQ0IiwiZXhwIjoxOTkzNzk2MDgwLCJpc3MiOiJteXNrb29sLmFwcCIsImF1ZCI6Im15c2tvb2wuYXBwIn0._pMXprq3AJApizQQlcrVXS3-dPyGFMi0nT9OfLQKBlo",
    };
  },
  computed: {
    imgUrl() {
      let img = require("@/assets/images/pages/error.svg");
      if (this.$store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        img = require("@/assets/images/pages/error-dark.svg");
        return img;
      }
      return img;
    },
  },
  created() {
    this.$store.commit("appConfig/UPDATE_CONTENT_WIDTH", "full");
    if (!this.$route.query.db || !this.$route.query.cID) {
      this.showError = true;
    } else {
      this.roomID = this.$route.params.id;
      this.db = this.$route.query.db;
      if (this.$route.query.name) {
        this.LoadName();
      }
      this.cID = this.$route.query.cID;
      //   this.LoadSchool();
    }
  },
  beforeDestroy() {
    this.$store.commit("appConfig/UPDATE_CONTENT_WIDTH", "boxed");
  },
  methods: {
    ...mapActions({
      get: "get",
      post: "post",
      put: "put",
      delete: "delete",
      postObj: "postObj",
      getString: "getString",
    }),

    hideDiv() {
      const iframe = this.$refs.myIframe;
      console.log(iframe, iframe.contentWindow);
      if (iframe && iframe.contentWindow) {
        console.log(iframe.contentWindow);
        const targetDiv = document.querySelector("iframe #toggleExtraBtn");
        console.log(targetDiv);
        // const iframeDocument = iframe.contentWindow.document;

        // // Find the element with the specific aria-describedby value
        // const targetDiv = iframeDocument.querySelector("#toggleExtraBtn");
        // // Hide the element if it exists
        // if (targetDiv) {
        //   targetDiv.style.display = "none";
        // }
      }
    },
    async LoadName() {
      this.schLoading = true;
      var obj = {
        url:
          "https://connect.myskool.app/api/Tokens/Decrypt?str=" +
          this.$route.query.name,
        token: this.token,
      };
      let res = await this.getString(obj);
      //   console.log("==============", res);
      this.schLoading = false;
      if (Array.isArray(res)) this.uname = "";
      else this.uname = res.data ? res.data : "";
    },
    async LoadSchool() {
      this.schLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "Campuses/GetSelected?id=" +
          this.cID +
          "&db=" +
          this.db,
        token: this.token,
      };
      let res = await this.get(obj);
      this.schLoading = false;
      if (res == "NotFound" || Array.isArray(res)) this.showError = true;
      else {
        this.school = res;
      }
      // console.log("sch:", this.school);
    },
  },
};
</script>
<style scoped>
.set-aspect {
  aspect-ratio: 16/9;
  height: 100dvh;
  /* height: calc(100dvh - (80px + 2rem)); */
}

/* @media (max-width: 768px) {
  .set-aspect {
    height: 80dvh;
  }
} */
</style>
